import { useContext, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import LoadingSpinner from '../shared/CustomLoadingSpinner/LoadingSpinner'
import UserService from '../../services/user/user.service'
import { CandidateService } from '../../services/candidate/candidate.service'
import JobService from '../../services/job/job.service'
import { UserContext } from '../../context'
import DataService from '../../services/data/data.service'
import { MasterDataContext } from '../../context/masterData'
import ErrorBannerModal from '../errorBannerModal/errorBannerModal'

const Redirect = () => {
  const location: any = useLocation()
  const [errorModel, setErrorModel] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const navigate = useNavigate()
  const userService = useMemo(() => new UserService(), [])
  const candidateService = useMemo(() => new CandidateService(), [])
  const dataService: DataService = useMemo(() => {
    return new DataService()
  }, [])
  const jobService = useMemo(() => new JobService(), [])
  const userData: any = useContext(UserContext)
  const masterData = useContext(MasterDataContext)

  function asyncFunction1() {
    return new Promise((resolve) => {
      resolve(userService.getUserDataT())
    })
  }

  function asyncFunction2() {
    return new Promise((resolve) => {
      resolve(candidateService.getCandidateDataT())
    })
  }

  function asyncFunction3() {
    return new Promise((resolve) => {
      resolve(dataService.getAllList())
    })
  }
  async function fetchData(userData: any) {
    try {
      const [data1, data2, data3]: any = await Promise.all([
        asyncFunction1(),
        asyncFunction2(),
        asyncFunction3(),
      ])
      userData.setUserData(data1)
      userData.setCandidateData(data2)
      masterData.setMasterData(data3)
    } catch (error: any) {
      setErrorModel(true)
      setErrorMessage(error.message)
    }
  }

  useEffect(() => {
    try {
      userService.isLoggedIn().then(async (res) => {
        if (!res) {
          const queryParams = new URLSearchParams(location.search)
          const codeParam: any = queryParams.get('code')
          if (codeParam) {
            const token = await userService.linkedInAuthorize(codeParam)
            if (token) {
              localStorage.setItem('token', token.access_token)
              localStorage.setItem('login_form', 'LinkedIn')
              localStorage.setItem('refreshToken', token.refresh_token)
              localStorage.setItem(
                'tokenExpiration',
                String(new Date().getTime() + token.expires_in * 1000)
              )
            }

            const maData = await dataService.getAllList()
            const uData = await userService.getUserDataT()
            const candidateData = await candidateService.getCandidateDataT()
            userData.setUserData(uData)
            if (candidateData && Object.keys(candidateData).length === 0) {
              userData.setCandidateData({ profile_pages: '{}' })
            } else {
              userData.setCandidateData(candidateData)
            }
            masterData.setMasterData(maData)

            const page = await candidateService.getLastPage()
            console.log(page)
            if (page === null) {
              navigate('/gettingStart')
            } else {
              if (await candidateService.isProfileCompleted()) {
                const assRes = await candidateService.getAssessmentResults()
                if (assRes === null || assRes.status !== 'success') {
                  navigate('/dashboard')
                } else {
                  navigate('/dashboard/topmatches')
                }
              } else {
                navigate('/' + page)
              }
            }
          } else {
            navigate('/signUp')
          }
        } else {
          const role = (await userService.getUserRole())?.role ?? ''
          const storedData = localStorage.getItem('masterData')
          if (storedData === 'undefined') {
            await fetchData(userData)
          } else {
            if (JSON.parse(storedData ?? '[]')?.length === 0) {
              await fetchData(userData)
            }
          }

          // Candidate Role
          if (role === 'user') {
            if (await candidateService.isProfileCompleted()) {
              if (
                (await userService.getAttribute('email_verified')) === 'true'
              ) {
                const assRes = await candidateService.getAssessmentResults()
                if (assRes === null || assRes.status !== 'success') {
                  navigate('/dashboard')
                } else {
                  navigate('/dashboard/topmatches')
                }
              } else {
                navigate('/emailVerification')
              }
            } else {
              const page = await candidateService.getLastPage()
              if (page === null) {
                navigate('/gettingStart')
              } else {
                navigate('/' + page)
              }
            }
            // Organization Role
          } else if (role === 'org_user') {
            const jobs = await jobService.getUserJobs()
            const user = await userService.getUserDataT()
            if ((await userService.getAttribute('email_verified')) === 'true') {
              // Update user table logic
              if (user && user.last_page) {
                navigate('/' + user.last_page)
              } else if (
                jobs === null ||
                jobs.status !== 'success' ||
                jobs.result.length === 0
              ) {
                navigate('/hireFor')
              } else {
                navigate('/jobs')
              }
            } else {
              navigate('/emailVerification')
            }
          }
        }
      })
    } catch (error: any) {
      navigate('/signUp')
      setErrorModel(true)
      setErrorMessage(error.message)
    }
    // eslint-disable-next-line
  }, [])
  return errorModel ? (
    <ErrorBannerModal
      open={errorModel}
      onClose={() => {
        setErrorModel(false)
      }}
      errorMessage={errorMessage}
    />
  ) : (
    <LoadingSpinner />
  )
}

export default Redirect
