import UserService from '../user/user.service'
import {
  AddRefereeCommentsRequest,
  GetRefereesCommentsRequest,
  InsertCertificationsRequest,
  InsertQualificationsRequest,
  InsertRefereeRequest,
  InsertWorkExperienceRequest,
  RemoveCertificationsRequest,
  RemoveQualificationsRequest,
  RemoveRefereeRequest,
  RemoveWorkExperienceRequest,
  RequestRefereeCommentRequest,
  SubmitAssessmentRequest,
  UpdateCandidateDataRequest,
  UpdateCandidateSummaryRequest,
  UpdateCertificationRequest,
  UpdateQualificationRequest,
  UpdateRefereeRequest,
  UpdateWorkExperienceRequest,
  UserProfilePage,
} from './candidate.req.model'
import HttpService from '../http/http.service'
import {
  GetFileResponse,
  UploadedFile,
  UploadedFileType,
} from '../file/file.model'
import FileService from '../file/file.service'
import {
  GetAssessmentDetailsResponse,
  GetAssessmentResultsResponse,
  GetCandidateDataResponse,
  GetCandidateSummaryResponse,
  GetCertificationsResponse,
  GetQualificationsResponse,
  GetRefereeCommentsResponse,
  GetRefereesResponse,
  GetTop3MatchesResponse,
  GetWorkExperienceResponse,
  StartAssessmentResponse,
  UpdateCandidateResponse,
  UpdateRefereeResponse,
} from './candidate.res.model'
import { SubmitAssessmentResponse } from './candidate.res.model'
import { UpdateUserDataResponse } from '../user/user.res.model'

export class CandidateService {
  constructor(
    private readonly userService: UserService = new UserService(),
    private readonly httpService: HttpService = new HttpService(),
    private readonly fileService: FileService = new FileService()
  ) {}

  // Extract data from a resume or LinkedIn profile document.
  public async extractData(
    file: UploadedFile,
    fileType: 'resume' | 'linkedIn' | string
  ): Promise<UpdateCandidateResponse | null> {
    if (!file.original) {
      return null
    }
    return this.httpService.sendPostRequest(
      'resume/' + (fileType === 'linkedIn' ? 'linkedIn' : 'extract'),
      await this.userService.getUserToken(),
      this.fileService.attachToForm(file.original)
    )
  }

  // Hard assessment requests and responses
  public async startAssessment(): Promise<StartAssessmentResponse | null> {
    return this.httpService.sendGetRequest(
      'assessment/start',
      await this.userService.getUserToken()
    )
  }

  public async submitAssessment(
    data: SubmitAssessmentRequest,
    duration: string
  ): Promise<SubmitAssessmentResponse | null> {
    return this.httpService.sendPostRequest(
      `assessment/submit?${new URLSearchParams({
        duration: duration,
      })}`,
      await this.userService.getUserToken(),
      data
    )
  }

  public async getAssessmentDetails(): Promise<GetAssessmentDetailsResponse | null> {
    return this.httpService.sendGetRequest(
      'assessment/details',
      await this.userService.getUserToken()
    )
  }

  public async getAssessmentResults(): Promise<GetAssessmentResultsResponse | null> {
    return this.httpService.sendGetRequest(
      'assessment/results',
      await this.userService.getUserToken()
    )
  }

  // Get and set the user's resume and cover letter
  public async getResume(): Promise<GetFileResponse | null> {
    return this.httpService.sendGetRequest(
      'candidate/resume/get',
      await this.userService.getUserToken()
    )
  }

  public async updateResume(
    file: UploadedFile
  ): Promise<UpdateCandidateResponse | null> {
    if (file.type !== UploadedFileType.File || !file.original) {
      return null
    }
    return this.httpService.sendPostRequest(
      'candidate/resume/update',
      await this.userService.getUserToken(),
      this.fileService.attachToForm(file.original)
    )
  }

  public async getCoverLetter(): Promise<GetFileResponse | null> {
    return this.httpService.sendGetRequest(
      'candidate/cover_letter/get',
      await this.userService.getUserToken()
    )
  }

  public async updateCoverLetter(
    file: UploadedFile
  ): Promise<UpdateCandidateResponse | null> {
    if (file.type !== UploadedFileType.File || !file.original) {
      return null
    }
    return this.httpService.sendPostRequest(
      'candidate/cover_letter/update',
      await this.userService.getUserToken(),
      this.fileService.attachToForm(file.original)
    )
  }

  // Get and set the candidate data.
  public async getCandidateDataT(): Promise<GetCandidateDataResponse | null> {
    return this.httpService.sendGetRequest(
      'candidate/get',
      await this.userService.getUserToken()
    )
  }

  public async updateCandidateData(
    data: UpdateCandidateDataRequest | null
  ): Promise<UpdateCandidateResponse | null> {
    return this.httpService.sendPostRequest(
      'candidate/update',
      await this.userService.getUserToken(),
      data
    )
  }

  // Get the user's top 3 job matches.
  public async getTop3Matches(): Promise<GetTop3MatchesResponse | null> {
    return this.httpService.sendGetRequest(
      'matches/jobs',
      await this.userService.getUserToken()
    )
  }

  // Get/add/remove/update the user's qualifications
  public async getQualifications(): Promise<GetQualificationsResponse | null> {
    return this.httpService.sendGetRequest(
      'candidate/qualifications/get',
      await this.userService.getUserToken()
    )
  }

  public async addQualifications(
    data: InsertQualificationsRequest
  ): Promise<UpdateCandidateResponse | null> {
    return this.httpService.sendPostRequest(
      'candidate/qualifications/insert',
      await this.userService.getUserToken(),
      data
    )
  }

  public async removeQualifications(
    data: RemoveQualificationsRequest
  ): Promise<UpdateCandidateResponse | null> {
    return this.httpService.sendPostRequest(
      'candidate/qualifications/remove',
      await this.userService.getUserToken(),
      data
    )
  }

  public async updateQualification(
    data: UpdateQualificationRequest
  ): Promise<UpdateCandidateResponse | null> {
    return this.httpService.sendPostRequest(
      'candidate/qualifications/update',
      await this.userService.getUserToken(),
      data
    )
  }

  // Get/add/remove/update the user's certifications
  public async getCertifications(): Promise<GetCertificationsResponse | null> {
    return this.httpService.sendGetRequest(
      'candidate/certifications/get',
      await this.userService.getUserToken()
    )
  }

  public async addCertifications(
    data: InsertCertificationsRequest
  ): Promise<UpdateCandidateResponse | null> {
    return this.httpService.sendPostRequest(
      'candidate/certifications/insert',
      await this.userService.getUserToken(),
      data
    )
  }

  public async removeCertifications(
    data: RemoveCertificationsRequest
  ): Promise<UpdateCandidateResponse | null> {
    return this.httpService.sendPostRequest(
      'candidate/certifications/remove',
      await this.userService.getUserToken(),
      data
    )
  }

  public async updateCertification(
    data: UpdateCertificationRequest
  ): Promise<UpdateCandidateResponse | null> {
    return this.httpService.sendPostRequest(
      'candidate/certifications/update',
      await this.userService.getUserToken(),
      data
    )
  }

  // Get/add/remove/update the user's work experience
  public async getWorkExperience(): Promise<GetWorkExperienceResponse | null> {
    return this.httpService.sendGetRequest(
      'candidate/workexperience/get',
      await this.userService.getUserToken()
    )
  }

  public async addWorkExperience(
    data: InsertWorkExperienceRequest
  ): Promise<UpdateCandidateResponse | null> {
    return this.httpService.sendPostRequest(
      'candidate/workexperience/insert',
      await this.userService.getUserToken(),
      data
    )
  }

  public async removeWorkExperience(
    data: RemoveWorkExperienceRequest
  ): Promise<UpdateCandidateResponse | null> {
    return this.httpService.sendPostRequest(
      'candidate/workexperience/remove',
      await this.userService.getUserToken(),
      data
    )
  }

  public async updateWorkExperience(
    data: UpdateWorkExperienceRequest
  ): Promise<UpdateCandidateResponse | null> {
    return this.httpService.sendPostRequest(
      'candidate/workexperience/update',
      await this.userService.getUserToken(),
      data
    )
  }

  // Get and set the user's last page
  public async getLastPage(): Promise<string | null> {
    const data = await this.userService.getUserDataT()
    return data ? data.last_page || null : null
  }

  public async updateLastPage(page: string): Promise<UpdateUserDataResponse> {
    return this.userService.updateUserData({ last_page: page })
  }

  // Get and set the user's skipped pages
  // Object { eachPage: complete | skipped }
  // use stringyfy to pass object to DB
  public async getProfilePages(): Promise<string | null> {
    const data = await this.getCandidateDataT()
    return data ? data.profile_pages || null : null
  }

  public async updateProfilePages(
    page: keyof UserProfilePage,
    action: string,
    candidateData: any
  ): Promise<UpdateUserDataResponse> {
    // {"gettingStart":null,"resumeImport":null,"qualification":null,"experience":null,"finish":null}
    let pagesFromDb: any = {}
    try {
      pagesFromDb =
        Object.keys(candidateData).length !== 0
          ? JSON.parse(candidateData?.profile_pages ?? {}) ?? {}
          : {}
      pagesFromDb[page] = action
    } catch (e) {
      pagesFromDb =
        Object.keys(candidateData).length !== 0
          ? candidateData?.profile_pages ?? {}
          : {}
      pagesFromDb[page] = action
    }
    if (page === 'finish' && (action === 'complete' || action === 'skip')) {
      return this.updateCandidateProfileData({
        profile_pages: JSON.stringify(pagesFromDb),
        profile_complete: true,
      })
    } else {
      return this.updateCandidateProfileData({
        profile_pages: JSON.stringify(pagesFromDb),
      })
    }
  }

  public async updateProfilePagesWithAllFunctions(
    page: keyof UserProfilePage,
    action: string,
    candidateData: any
  ): Promise<UpdateUserDataResponse> {
    // {"gettingStart":null,"resumeImport":null,"qualification":null,"experience":null,"finish":null}
    const pagesFromDb: UserProfilePage =
      Object.keys(candidateData).length !== 0
        ? JSON.parse(candidateData?.profile_pages ?? {}) ?? {}
        : {}
    pagesFromDb[page] = action
    if (page === 'finish' && (action === 'complete' || action === 'skip')) {
      return this.updateCandidateData({
        profile_pages: JSON.stringify(pagesFromDb),
        profile_complete: true,
      })
    } else {
      return this.updateCandidateData({
        profile_pages: JSON.stringify(pagesFromDb),
      })
    }
  }

  // Get the user profile_complete value
  public async isProfileCompleted(): Promise<boolean> {
    const data = await this.getCandidateDataT()
    return data ? data.profile_complete || false : false
  }

  // Get/update/generate candidate summary
  public async getSummary(): Promise<GetCandidateSummaryResponse | null> {
    return this.httpService.sendGetRequest(
      'candidate/summary/get',
      await this.userService.getUserToken()
    )
  }

  public async updateSummary(
    data: UpdateCandidateSummaryRequest
  ): Promise<UpdateCandidateResponse | null> {
    return this.httpService.sendPostRequest(
      'candidate/summary/update',
      await this.userService.getUserToken(),
      data
    )
  }

  public async generateOverview(): Promise<UpdateCandidateResponse | null> {
    return this.httpService.sendGetRequest(
      'candidate/summary/generate_overview',
      await this.userService.getUserToken()
    )
  }

  public async generateKeySkillsSummary(): Promise<UpdateCandidateResponse | null> {
    return this.httpService.sendGetRequest(
      'candidate/summary/generate_key_skills_summary',
      await this.userService.getUserToken()
    )
  }

  // Get/insert/update/remove referees
  public async getReferees(): Promise<GetRefereesResponse | null> {
    return this.httpService.sendGetRequest(
      'candidate/referees/get',
      await this.userService.getUserToken()
    )
  }

  public async insertReferee(
    data: InsertRefereeRequest
  ): Promise<UpdateCandidateResponse | null> {
    return this.httpService.sendPostRequest(
      'candidate/referees/insert',
      await this.userService.getUserToken(),
      data
    )
  }

  public async updateReferee(
    data: UpdateRefereeRequest
  ): Promise<UpdateRefereeResponse | null> {
    return this.httpService.sendPostRequest(
      'candidate/referees/update',
      await this.userService.getUserToken(),
      data
    )
  }

  public async removeReferee(
    data: RemoveRefereeRequest
  ): Promise<UpdateCandidateResponse | null> {
    return this.httpService.sendPostRequest(
      'candidate/referees/remove',
      await this.userService.getUserToken(),
      data
    )
  }

  // Request referee comment
  public async requestRefereeComment(
    data: RequestRefereeCommentRequest
  ): Promise<UpdateCandidateResponse | null> {
    return this.httpService.sendPostRequest(
      'candidate/referees/request',
      await this.userService.getUserToken(),
      data
    )
  }

  // Get referee comments as a referee
  public async getRefereeComments(
    data: GetRefereesCommentsRequest
  ): Promise<GetRefereeCommentsResponse | null> {
    return this.httpService.sendPostRequest(
      'candidate/referees/getcomments',
      undefined,
      data
    )
  }

  // Add a referee comment as a referee
  public async addRefereeComments(
    data: AddRefereeCommentsRequest
  ): Promise<UpdateCandidateResponse | null> {
    return this.httpService.sendPostRequest(
      'candidate/referees/postcomment',
      await this.userService.getUserToken(),
      data
    )
  }

  public async updateCandidateProfileData(
    data: UpdateCandidateDataRequest | null
  ): Promise<UpdateCandidateResponse | null> {
    return this.httpService.sendPostRequest(
      'candidate/update_data',
      await this.userService.getUserToken(),
      data
    )
  }

  public async getPreSignUrlResume(): Promise<UpdateCandidateResponse | null> {
    return this.httpService.sendGetRequest(
      'candidate/get_pre_sign_url_resume',
      await this.userService.getUserToken()
    )
  }

  public async getPreSignUrlCoverLetter(): Promise<UpdateCandidateResponse | null> {
    return this.httpService.sendGetRequest(
      'candidate/get_pre_sign_url_cover_letter',
      await this.userService.getUserToken()
    )
  }
}
